.connectGroup {
  font-size: 2.25rem;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectGroup:hover {
  transform: scale(1.5);
  transition: 0.5s;
  color: #8268ae;
  
}
.socialMediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faceBook:hover {
  transform: scale(1.3);
  transition: 0.7s;
}
.twitter:hover {
  transform: scale(1.3);
  transition: 0.7s;
}
.instagram:hover {
  transform: scale(1.3);
  transition: 0.7s;
}
.socialMedia,
.socialMediaFacebook,
.socialMediaTwitter,
.socialMediaInstagram {
  margin: 4px;
}

.socialMediaFacebook {
  color: #0860f2;
  font-size: 28px;
}
/* .socialMediaFacebook:hover {
  transform: scale(1.2);
  transition: 0.5s;
} */
.socialMediaTwitter {
  color: black;
  font-size: 28px;
}

.socialMediaInstagram {
  color: #f176ce;
  font-size: 28px;
}

.loginBtn {
  background: var(--primary-color);
  color: #fff;
  width: 90px;
  border-radius: 5px;
  padding: 4px 6px;
  border: 2px solid white;
  font-weight: bold;
  line-height: 25px;
}
.logoutBtn{
  background-color: #7C9B16;
  border-color: #7C9B16;
  color: white;
  border-radius: 5px;
  padding: 4px 8px;
  border: 1px solid #7C9B16;
  font-weight: bold;
  border: 4px solid white;
  border-style: outset;
}

.loginBtn:hover {
  color: var(--primary-color);
  background: var(--light-color);
  border: 1.5px solid var(--primary-color);
  font-weight: bold;
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
  transition: 0.7s;
}
.organization,
.emailHeading {
  

  color:#525557;
  
  font-size: 1.2rem;
}
.organization:hover {
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
  transition: 0.7s;
  transform: scale(1.3);
}

.localOrg:hover {
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
  transition: 0.7s;
}

.localOrgs {
  background: var(--primary-color);
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  padding: 4px 8px;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  border: 4px solid white;
  border-style: outset;
}
.localOrgs:hover {
  /* color: #8268ae;
  background: none; */
 color: #AD0701;
  /* transform: scale(1.1);*/
  transition: 1s; 
  /* border: none;
  border-style: none; */
}
.localOrgs:active{
  transform: scale(1.1);
  transition: 0.5s;
  color: #f8ad00fc;
}

.brnadName {
  
  color:#525557;
  font-size: 27px;
  background: none;
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
 
}
.brnadName:hover {
  background: none;
  
  transform: scale(1.3);
  transition: 0.7s;
}

.ctsLogo {
  height: 35px;
  width: 40px;
  mix-blend-mode: multiply;
}
.ctsLogo:hover {
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
  transition: 0.5s;
}
.divOrg {
  display: block;
  justify-content: center;
}
.emailHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 900;
  padding: 3px;
}
.emailHeading:hover {
  font-weight: bold;
  transform: matrix(1.1, 0, 0, 1.1, 0, 0);
  transition: 0.7s;
}
.email,
.phone {
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #7c9b16;
  font-weight: 900;
  gap: 8px;
}
.email:hover {
  transform: scale(1.1);
  transition: 0.7s;
}

@media (max-width: 768px) {
  .brnadName {
    font-size: 18px;
  }
  .brnadName:hover {
    transform: scale(1.1);
    transition: 0.7s;
  }

  .emailHeading {
    font-size: 1.1rem;
  }

  .email,
  .phone {
    font-size: 1rem;
  }
}

 @media (max-width: 1024px) {
  .brnadName {
    font-size: 18px;
  }
  .localOrgs {
    background: var(--primary-color);
    font-size: 23px;
    
  }
}

@media (max-width: 1161px) {
  .brnadName {
    font-size: 14px;
  }
}
@media (max-width: 1205px) {
  .brnadName {
    font-size: 22px;
  }
}

@media (max-width: 1142px) {
  .brnadName {
    font-size: 20px;
  }
}
