
.certificateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  margin-left: 10px;
 
  padding: 15px;
  background-color: #7c9b16;
 
}
.exclamationFamily{
  color:#fabf36
 
}
.paraFirst, .paraSecond, .paraThird{
  font-family:  sans-serif;
  color:white;
  font-size: 18px;
}
.paraFourth{
 
  color:white;
}
.paraFifth{
  color:#fabf36;
  font-size: 14px;
}
.spanFamily:hover{
  font-size: 19px;
  color: #fabf36;
  text-decoration: underline;
}
.welcomeHeader{
  margin-top: 25px;
  color: #fabf36;
}
.welcomeMessage{
  font-family:   Roman;
  color:white;
 
  margin-bottom: 20px;
}
.welcomeMessage:hover{
  transform: scale(1.1);
  transition: 0.5s;
  
}
.welcomeFamily{
  font-family:  Roman;
  color:white;
  font-size: 18px;
  margin-bottom: 10px;
}
.qrMessage{
  color:white;
  font-family: kantipur;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.label {
  margin-top: 25px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.uploadInput {
  padding: 4px;
  width: 100%;
  font-size: 17px;
  margin-top: 12px;
  margin-left:4px ;
 
} 
 .generateButton {
 padding: 6px;
 
 margin-bottom: 15px;
 font-size: 15px;
 background-color: #B0C272;
 
} 

@media (max-width: 768px) {
  .certificateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px;
    
  }
  .paraFirst, .paraSecond, .paraThird,.paraFourth{
    font-size: 18px;
   
  }
  
  .welcomeMessage{
    font-size: 22px;
    color: #fabf36;
  }
  .welcomeFamily{
    font-size: 22px;
  }
  .qrMessage{
    font-size: 20px;
    margin-top: 10px;

  }
  .generateButton {
    padding: 5px;
    margin-bottom: 12px;
    font-size: 12px;
    background-color: #B0C272;
    
   } 
   .qrMessage{
    color:#fabf36;
    font-family: kantipur;
    font-size: 13.5px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
 
}

@media (min-width: 769px) {
  /* For larger screens */
  .certificateContainer {
    width: 100%;
    
  }
  .welcomeMessage{
    font-size: 22px;
    color: #fabf36;
  }
  .paraFirst, .paraSecond, .paraThird,.paraFourth{
    font-size: 18px;
  }
  .qrMessage{
    color:#fabf36;
    font-family: kantipur;
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

 
}
