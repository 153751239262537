table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: var(--primary-color);
}

tr:nth-child(odd) {
    background-color: var(--secondary-color);
}