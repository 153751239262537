.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  grid-auto-rows: minmax(100px, auto);
}

.wrapper>div {
  background-color: var(--light-gray-color);
  padding: 1em;
}

/* .wrapper > div:nth-child(odd){
    background-color:#ddd;
} */

.event-item,
.birthday-item {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.member-item {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.event,
.birthday {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.event>div,
.birthday>div {
  border: none;
  padding: 0.3em;
}

.event .todays-events,
.event .upcoming-events,
.birthday .todays-birthdays,
.birthday .upcoming-birthdays {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: minmax(50px, auto);
}

.event .todays-events>div,
.event .upcoming-events>div,
.birthday .todays-birthdays>div,
.birthday .upcoming-birthdays>div {
  border: var(--secondary-color) solid 1px;
}

@media (max-width: 768px) {

  .wrapper,
  .birthday,
  .birthday .todays-birthdays,
  .birthday .upcoming-birthdays,
  .event,
  .event .todays-events,
  .event .upcoming-events {
    grid-template-columns: 1fr;
  }

  .wrapper {
    margin-top: 1rem;
  }
}

.scrollable {
  height: 500px;
  /* Fixed height */
  overflow-y: auto;
  /* Vertical scrolling */
  overflow-x: hidden;
  /* Hides horizontal scrolling */
  border: 1px solid #ccc;
  /* Optional styling */
  padding: 10px;
  /* Optional spacing */
}