.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1 rem;
}

.pagination button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: black;
    background-color: #eee;
}

.pagination button.active {
    font-weight: 900;
    border-color: #101010;
    background-color: #ffe400;
    color: #101010;
}

.search-container {
    color: var(--primary-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    float: right;
}

.search-container input {
    font-size: 20px;
    padding: 4px;
    border-color: var(--primary-color);
}

@media (max-width: 768px) {

    .search-container,

    .search-container {
        float: left;
    }
}