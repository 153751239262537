
.flexContainer{

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 10px;
  margin: auto;

}
.orgLogo{
  height: 28px;
  width: 28px;
  padding: 1px;
  mix-blend-mode: multiply;
}
.orgLogo:hover{
 
  transition: 0.5s;
  transform: scale(1.5);

}
.orgName{
  font-weight: bold;
  font-size: 10px;
  /* color: #7353aa; */
  color: red;
}
.orgName:hover{
  color: rgb(233, 142, 142);
  transition: 0.5s;
  transform: scale(1.2);

}
